import { Button, notification, Space } from "antd"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import facebookLogo from "../../../../assets/images/f_logo_RGB-White_1024.png"
import { MouseEventHandler } from "react"
import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from "react-facebook-login"
import { AccessAPI } from "../../../../api/common/AccessAPI"
import axios, { AxiosError } from "axios"

export default function FacebookButtons() {
  function facebookResponse(response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) {
    if ("status" in response) return
    const facebookResponse = response as ReactFacebookLoginInfo
    let name = undefined
    let surname = undefined
    if (facebookResponse.name !== undefined) {
      const splitName = facebookResponse.name.split(" ", 2)
      name = splitName.at(0)
      surname = splitName.at(1)
    }
    AccessAPI.facebookLogin(
      facebookResponse.accessToken,
      facebookResponse.userID,
      facebookResponse.email,
      name,
      surname,
    )
      .then(() => {
        window.location.reload()
      })
      .catch((error: Error | AxiosError) => {
        let errorMessage = "Qualcosa è andato storto"
        if (axios.isAxiosError(error)) {
          const responseMessage = error.response?.data.error.message
          if (responseMessage === "Mail already exists!") errorMessage = "L'email è già registrata"
          else if (responseMessage === "Facebook Authentication failed!")
            errorMessage = "Autenticazione con Facebook fallita"
        }
        notification.error({
          message: errorMessage,
          duration: 4,
        })
      })
  }

  return (
    <FacebookLogin
      appId={"1485847518136206"}
      fields="name,email"
      callback={facebookResponse}
      disableMobileRedirect={true}
      isMobile={false}
      render={(renderProps: { onClick: MouseEventHandler<HTMLElement> | undefined }) => (
        <Space wrap>
          <Button className="facebook-btn" onClick={renderProps.onClick} size="large">
            <Space align={"center"}>
              <img src={facebookLogo} height={30} alt={"logo"} className={"fb-logo"} />
              Continua con Facebook
            </Space>
          </Button>
        </Space>
      )}
    />
  )
}
