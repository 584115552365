import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import translationEN from "./locales/en/translation.json"
import translationIT from "./locales/it/translation.json"

const resources = {
  en: {
    translation: translationEN,
  },
  it: {
    translation: translationIT,
  },
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "it",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18n

// export function changeLanguage(language) {
//   i18n.changeLanguage(language)
// }
