import React, { ReactNode, useEffect, useState } from "react"
import "./ComixtimeContainer.scss"
import { Layout } from "antd"
import { DefaultHeader } from "frontend/components/Layout/DefaultHeader/DefaultHeader"
import { DefaultFooter } from "frontend/components/Layout/DefaultFooter/DefaultFooter"
import ServiceContext from "frontend/context/service/ServiceContext"
import { useLocation } from "react-router"

export const ComixtimeContainer = ({ footer, children }: { footer?: ReactNode; children: ReactNode }) => {
  const location = useLocation()

  const [service, setService] = useState<"dex" | "pub" | "">("")

  useEffect(() => {
    if (location.pathname.includes("dex")) setService("dex")
    else if (location.pathname.includes("pub")) setService("pub")
  }, [])

  return (
    <Layout className={"comixtime-layout"}>
      <div className={"comixtime-header"}>
        <DefaultHeader setService={(service) => setService(service)} />
      </div>
      <div className={"comixtime-content-div"}>
        <div className={"comixtime-content-inner-div"}>
          <ServiceContext.Provider value={service.toUpperCase()}>{children}</ServiceContext.Provider>
        </div>
      </div>
      {footer || <DefaultFooter />}
    </Layout>
  )
}
