//user = 1; collezionista = 2; admin = 3; superuser = 4; gestore_fumetteria = 5; chosen = 6; mefu = 7
export enum Roles {
  User = 1,
  Collector,
  Admin,
  Superuser,
  ComicShopManager,
  Chosen,
  Mefu,
  Pub,
}
