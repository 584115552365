import { EnvConstants } from "./envs"
import { UrlsConstant } from "./urls"

export const LANGUAGE_HEADER = "language"

export const ENV = process.env.REACT_APP_ENV

export const CODEK_API_URL =
  EnvConstants.ENV === EnvConstants.PRODUCTION
    ? "https://codek.comixtime.it/"
    : EnvConstants.ENV === EnvConstants.STAGING
    ? "https://codek.comixtimestage.it/"
    : "http://localhost:23146/"
export const POST = "POST"
export const PUT = "PUT"
export const GET = "GET"
export const PATCH = "PATCH"
export const DELETE = "DELETE"
export const DEX = "dex/"
export const OAK = "oak/"
export const COMMON = "common/"
export const PLATFORM = "platform/"
export const ADM = "adm/"
export const V1 = "v1/"
export const V2 = "v2/"

export const LOGIN_PATH = "/login"
export const USER_PATH = "/users"
export const AUTH_PATH = "/authenticate"
export const FACEBOOK_PATH = "/facebook_login"
export const APPLE_PATH = "/apple_login"
export const LOGOUT_PATH = "/logout"
export const COMIC_PATH = "/comics"
export const SERIES_PATH = "/series"
export const COMICSET_PATH = "/comicsets"
export const PUBLISHER_PATH = "/publishers"
export const AUTHOR_PATH = "/authors"
export const PERSONA_PATH = "/personas"
export const GENRE_PATH = "/genres"
export const STORY_PATH = "/stories"
export const COUNTRY_PATH = "/countries"
export const ACTIVITY_PATH = "/activities"
export const SCHOOL_PATH = "/schools"
export const MARKET_PATH = "/markets"

export const COMICS_CREATE_MANY_API_PATH = COMIC_PATH + "/createMany"
export const COMICSHOPS_API_PATH = "comicshops"
// export const COMICS_GET_COMICS_PAGINATED_API_PATH = COMICSHOPS_API_PATH + "/:idComicshop/comics/getPaginated"
export const COMICS_GET_BY_ID_API_PATH = COMIC_PATH + "/:id"
export const SERIES_GET_BY_ID_API_PATH = SERIES_PATH + "/:id"
export const COMICSET_GET_BY_ID_API_PATH = COMICSET_PATH + "/:id"
export const AUTHOR_GET_BY_ID_PATH = AUTHOR_PATH + "/:id"
export const PERSONA_GET_BY_ID_PATH = PERSONA_PATH + "/:id"
export const PUBLISHER_GET_BY_ID_PATH = PUBLISHER_PATH + "/:id"
export const GENRE_GET_BY_ID_PATH = GENRE_PATH + "/:id"

export const COMIC_UPDATE_STATE = COMIC_PATH + "/updateState"
export const SERIES_UPDATE_STATE = SERIES_PATH + "/updateState"
export const COMICSET_UPDATE_STATE = COMICSET_PATH + "/updateState"
export const AUTHOR_UPDATE_STATE = AUTHOR_PATH + "/updateState"
export const PERSONA_UPDATE_STATE = PERSONA_PATH + "/updateState"
export const PUBLISHER_UPDATE_STATE = PUBLISHER_PATH + "/updateState"
export const GENRE_UPDATE_STATE = GENRE_PATH + "/updateState"

export const FIND_SERIES = SERIES_PATH + "/find"
export const FIND_COMICSETS = COMICSET_PATH + "/find"
export const FIND_PUBLISHERS = PUBLISHER_PATH + "/find"
export const FIND_AUTHORS = AUTHOR_PATH + "/find"
export const FIND_PERSONAS = PERSONA_PATH + "/find"
export const FIND_GENRES = GENRE_PATH + "/find"
export const FIND_STORIES = STORY_PATH + "/find"
export const FIND_COUNTRIES = COUNTRY_PATH + "/find"
export const FIND_ACTIVITIES = ACTIVITY_PATH + "/find"
export const FIND_SCHOOLS = SCHOOL_PATH + "/find"
export const FIND_MARKETS = MARKET_PATH + "/find"

export const IS_UNIQUE_COMIC_PATH = COMIC_PATH + "/isUnique"
export const IS_UNIQUE_SERIES_PATH = SERIES_PATH + "/isUnique"
export const IS_UNIQUE_COMICSET_PATH = COMICSET_PATH + "/isUnique"
export const IS_UNIQUE_AUTHOR_PATH = AUTHOR_PATH + "/isUnique"
export const IS_UNIQUE_PERSONA_PATH = PERSONA_PATH + "/isUnique"
export const IS_UNIQUE_PUBLISHER_PATH = PUBLISHER_PATH + "/isUnique"
export const IS_UNIQUE_GENRE_PATH = GENRE_PATH + "/isUnique"

export const CERTIFY_COMIC = COMIC_PATH + "/certify"
export const CERTIFY_ALL_COMICS = COMIC_PATH + "/certifyAll"
export const CERTIFY_SERIES = SERIES_PATH + "/certify"
export const CERTIFY_COMICSET = COMICSET_PATH + "/certify"
export const GET_COMIC_DATA = COMIC_PATH + "/getData"
export const GET_COMICS_PAGINATED = COMIC_PATH + "/paginated"
export const GET_SERIES_PAGINATED = SERIES_PATH + "/paginated"
export const GET_COMICSETS_PAGINATED = COMICSET_PATH + "/paginated"
export const GET_AUTHORS_PAGINATED = AUTHOR_PATH + "/paginated"
export const GET_PERSONAS_PAGINATED = PERSONA_PATH + "/paginated"
export const GET_PUBLISHERS_PAGINATED = PUBLISHER_PATH + "/paginated"
export const GET_GENRES_PAGINATED = GENRE_PATH + "/paginated"

export const SCRAPING_PATH = "/scraping"

export const GET_USER_STATS = USER_PATH + "/stats"
export const GET_USER_STAT = USER_PATH + "/stats"
export const CERTIFY_ALL = USER_PATH + "/certifyAll"
export const GET_COMIC_COLLECTORS = USER_PATH + COMIC_PATH + "/:id"
export const ACCEPT_PUB_CONSENTS = USER_PATH + "/pub/consents"

export const COMIC_VINE_PATH = COMIC_PATH + "/comicVine/:id"

export const GET_ISSN_PATH = COMIC_PATH + "/issn"

// TODO: fix pointer
export const CLOUD_IMAGE_BASE_PATH =
  EnvConstants.ENV !== EnvConstants.PRODUCTION ? UrlsConstant.URL_IMAGE_STAGE : UrlsConstant.URL_IMAGE
