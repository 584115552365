import { useContext } from "react"
import AuthContext from "./AuthContext"

const useAuthContext = () => {
  const user = useContext(AuthContext)

  if (user === undefined) {
    throw new Error("Access denied")
  }
  return user
}

export default useAuthContext
