import { AuthContextData } from "../../types/user/AuthContextData"
import useAuthContext from "../../context/auth/useAuthContext"
import { Button, Col, Divider, Image, Modal, notification, Progress, Row, Spin } from "antd"
import { useEffect, useState } from "react"
import { UserAPI } from "../../api/platform/UserAPI"
import { Stat } from "../../types/dashboard/Stat"
import "./index.scss"
import { DatabaseFilled } from "@ant-design/icons"
import DefaultImg from "../../assets/images/defaultComic.png"
import { CLOUD_IMAGE_BASE_PATH } from "../../constants/apis"
import CountUp from "react-countup"
import { useNavigate } from "react-router"

export default function HomePage() {
  const user: AuthContextData = useAuthContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [stat, setStat] = useState<Stat>()
  const [modalLoading, setModalLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    if (location.pathname == "/firstaccess" && user.role.includes(8)) setModalOpen(true)
    if (!stat) {
      UserAPI.getStats()
        .then((response) => setStat(response))
        .catch(() => console.log("Statistiche non disponibili"))
        .finally(() => {
          setLoading(false)
        })
    }
  }, [])
  const showProgress = (label: string, approved: number, checked: number, total: number) => {
    return (
      <Col flex={1}>
        <h1>{label}</h1>
        <CountUp start={0} end={total} duration={1} delay={0} separator={"."}>
          {({ countUpRef }) => (
            <h2 className={"number"}>
              <b>
                <span ref={countUpRef} />
              </b>
            </h2>
          )}
        </CountUp>
        <Progress
          type="dashboard"
          strokeLinecap={"round"}
          strokeColor={"#00B27E"}
          success={{ percent: checked / total }}
          percent={parseFloat(((checked / total) * 100).toFixed(2))}
        />
        <br />
        <CountUp start={0} end={checked} duration={1} delay={0}>
          {({ countUpRef }) => (
            <h5>
              Certificati: <span ref={countUpRef} /> / {total}
            </h5>
          )}
        </CountUp>
        {!user.role.includes(8) && (
          <CountUp start={0} end={approved} duration={1} delay={0}>
            {({ countUpRef }) => (
              <h5>
                Approvati: <span ref={countUpRef} /> / {total}
              </h5>
            )}
          </CountUp>
        )}
      </Col>
    )
  }

  return (
    <Spin spinning={loading}>
      <Row justify={"center"} align={"middle"} className={"home"}>
        {stat ? (
          <Col flex={1}>
            <h1>
              <DatabaseFilled /> Dati su ComiXtime
            </h1>
            {!user.role.includes(8) && (
              <Row justify={"center"} align={"middle"} className={"mb-5"}>
                <Col flex={1}>
                  {showProgress(
                    "Volumi",
                    stat.general_stats.comics.num_approved,
                    stat.general_stats.comics.num_certified,
                    stat.general_stats.comics.num_total,
                  )}
                </Col>
                <Col flex={1}>
                  {showProgress(
                    "Collane",
                    stat.general_stats.series.num_approved,
                    stat.general_stats.series.num_certified,
                    stat.general_stats.series.num_total,
                  )}
                </Col>
                <Col flex={1}>
                  {showProgress(
                    "Volumi",
                    stat.general_stats.comicsets.num_approved,
                    stat.general_stats.comicsets.num_certified,
                    stat.general_stats.comicsets.num_total,
                  )}
                </Col>
              </Row>
            )}
            {user.role.includes(8) && (
              <Row justify={"center"} align={"middle"}>
                <Col flex={1}>
                  {stat.pub_stats.publishers_stats.map((pub, index: number) => {
                    return (
                      <>
                        <Divider orientation={"center"} style={{ borderColor: "#001529" }} />
                        <Row justify={"center"} align={"middle"} className={"m-3"} key={index}>
                          <Col>
                            {pub.publisher.multimedia?.[0]?.url ? (
                              <Image
                                alt={pub.publisher.multimedia[0].url}
                                height={200}
                                width={200}
                                src={CLOUD_IMAGE_BASE_PATH + pub.publisher.multimedia[0].url}
                                fallback={DefaultImg}
                                preview={false}
                              />
                            ) : (
                              <h1>
                                <cite>{pub.publisher.denomination}</cite>
                              </h1>
                            )}
                          </Col>
                          <Col span={8} offset={1} className={"ant-col"}>
                            <Row justify={"start"}>
                              <h3>{pub.publisher.denomination}</h3>
                            </Row>
                            <Row justify={"start"} align={"top"}>
                              <p>{pub.publisher.description}</p>
                            </Row>
                          </Col>
                        </Row>
                        <Row justify={"center"} align={"middle"} className={"m-3"}>
                          {showProgress(
                            "Volumi",
                            pub.comics.num_approved,
                            pub.comics.num_certified,
                            pub.comics.num_total,
                          )}
                          {showProgress(
                            "Collane",
                            pub.series.num_approved,
                            pub.series.num_certified,
                            pub.series.num_total,
                          )}
                          {showProgress(
                            "Serie",
                            pub.comicsets.num_approved,
                            pub.comicsets.num_certified,
                            pub.comicsets.num_total,
                          )}
                        </Row>
                      </>
                    )
                  })}
                </Col>
              </Row>
            )}
          </Col>
        ) : (
          <h1>Caricando le statistiche...</h1>
        )}
      </Row>
      <Modal
        open={modalOpen}
        title="Certifica i tuoi dati"
        closable={true}
        className="confirm"
        footer={[
          <Button
            key="back"
            danger
            onClick={() => {
              setModalOpen(false)
              navigate("/")
            }}
          >
            Annulla
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={modalLoading}
            onClick={() => {
              setModalLoading(true)
              UserAPI.certifyAll("PUB")
                .then(() => {
                  notification.success({
                    message: "I dati sono stati certificati con successo",
                    duration: 2,
                  })
                  navigate("/")
                })
                .catch(() =>
                  notification.error({
                    message: "Si è verificato un errore.",
                    duration: 4,
                  }),
                )
                .finally(() => setModalLoading(false))
            }}
          >
            Conferma
          </Button>,
        ]}
      >
        Clicca "Conferma" per certificare tutti i tuoi Albi, Serie e Collane in ComiXtime
      </Modal>
    </Spin>
  )
}
