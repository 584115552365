import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { ComicsAPI } from "../../api/platform/ComicsAPI"
import { Filter } from "../../types/dashboard/Filter"
import { PaginatedComic } from "../../types/dashboard/PaginatedComic"
import { ComicData } from "../../types/entity/comic/ComicData"
import { CardPanel } from "../../components/Layout/CardPanel/CardPanel"
import { ComicFilter } from "../../types/entity/comic/ComicFilter"
import { ComicExploreCard } from "../../components/Card/ComicExploreCard/ComicExploreCard"
import { Button, Col, Image, Row } from "antd"
import logo from "../../assets/images/logo_light.png"
import { LayoutConstants } from "../../constants/layout"
import { Sorting } from "../../types/dashboard/Sorting"

export default function ComicsExplore({ onExplore }: { onExplore: Dispatch<SetStateAction<boolean>> }) {
  // control
  const [loading, setLoading] = useState<boolean>(false)
  const [lastRequest, setLastRequest] = useState<AbortController>()
  const [filter, setFilter] = useState<Filter>({})
  const [cards, setCards] = useState<ReactNode[]>([])
  const [numCards, setNumCards] = useState<number>(12)
  const [search, setSearch] = useState<string>("")
  const [filters, setFilters] = useState<ComicFilter>({})
  const [sort, setSort] = useState<Sorting>({})
  // fields
  const [numRecords, setNumRecords] = useState<number>(0)
  const [numPages, setNumPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)

  useEffect(() => {
    setCurrentPage(1)
    setFilter((prevState) => ({
      ...prevState,
      search: search,
      page: 1,
      filters: filters,
      sort: sort,
    }))
  }, [search, filters, sort])

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      pageSize: numCards,
      page: currentPage,
    }))
  }, [currentPage, numCards])

  useEffect(() => {
    if (filter) {
      if (lastRequest !== undefined) {
        lastRequest.abort()
      }
      const controller = new AbortController()
      setLastRequest(controller)
      setLoading(true)
      ComicsAPI.getPaginatedExplore(filter, controller)
        .then((response: PaginatedComic) => {
          setNumRecords(response.num_records)
          setNumPages(response.num_pages)
          const comicCards = response.data?.map((comic: ComicData) => {
            return <ComicExploreCard comic={comic} />
          })
          setCards(comicCards)
          setLoading(false)
        })
        .catch((e) => {
          e.message === "canceled" ? setLoading(true) : setLoading(false)
        }) // TODO: handle connection errors and 400
    }
  }, [filter])

  return (
    <>
      <Row justify={"center"} align={"middle"} className={"comixtime-layout"}>
        <Col flex={1}>
          <Image
            alt={"cxt"}
            height={LayoutConstants.SEARCH_LOGO_HEIGHT}
            width={LayoutConstants.SEARCH_LOGO_WIDTH}
            src={logo}
            preview={false}
            fallback={"EXPLORE"}
          />
          <CardPanel
            cards={cards}
            loading={loading}
            isFilterSiderCollapsed={true}
            pages={numPages}
            isEmpty={numRecords === 0}
            changePage={(page: number) => setCurrentPage(page)}
            initialPage={currentPage}
            onSearch={setSearch}
            setNumCards={setNumCards}
            pageWindowSize={2}
          />
          <Button className={"mb-5"} type={"primary"} onClick={() => onExplore(false)}>
            Accedi a Platform
          </Button>
        </Col>
      </Row>
    </>
  )
}
