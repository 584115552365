import { notification, Space } from "antd"
import AppleLogin from "react-apple-login"
import { useEffect } from "react"
import { AccessAPI } from "../../../../api/common/AccessAPI"
import axios, { AxiosError } from "axios"
import { EnvConstants } from "../../../../constants/envs"
import "./AppleButton.scss"

export default function AppleButton() {
  useEffect(() => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/it_IT/appleid.auth.js"
    document.body.appendChild(script)
  }, [])

  function appleResponse(response: any) {
    if ("error" in response) return
    AccessAPI.appleLogin(response.authorization.id_token)
      .then(() => {
        window.location.reload()
      })
      .catch((error: Error | AxiosError) => {
        let errorMessage = "Qualcosa è andato storto"
        if (axios.isAxiosError(error)) {
          const responseMessage = error.response?.data.error.message
          if (responseMessage === "Mail already exists!") errorMessage = "L'email è già registrata"
        }
        notification.error({
          message: errorMessage,
          duration: 4,
        })
      })
  }
  return (
    <>
      <AppleLogin
        clientId={"it.comixtime.platform"}
        redirectURI={
          EnvConstants.ENV === EnvConstants.PRODUCTION
            ? "https://platform.comixtime.it"
            : "https://platform.comixtimestage.it"
        }
        usePopup={true}
        callback={appleResponse}
        scope="name"
        responseMode="query"
        render={() => (
          <Space wrap>
            <div
              id="appleid-signin"
              className="signin-button apple-button"
              data-color="black"
              data-border="true"
              data-type={"continue"}
            ></div>
          </Space>
        )}
      />
    </>
  )
}
