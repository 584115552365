import { Button, Col, Divider, InputNumber, Row } from "antd"
import { useEffect, useState } from "react"
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons"
import "./NavigatePages.scss"

export const NavigatePages = ({
  pages,
  initialPage,
  pageWindowSize,
  changePage,
}: {
  pages: number
  initialPage: number
  pageWindowSize: number
  changePage: (page: number) => void
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  useEffect(() => {
    setCurrentPage(initialPage)
  }, [initialPage])

  useEffect(() => {
    changePage(currentPage)
  }, [currentPage])

  const getPreviousPages = (length: number) => {
    const cells = []
    for (let index = 1; currentPage - index > 1 && index <= length; index++) {
      cells.push(
        <>
          <Col flex={1}>
            <span onClick={() => setCurrentPage(currentPage - index)}>{currentPage - index}</span>
          </Col>
          <Divider type={"vertical"} style={{ borderColor: "lightgray" }} />
        </>,
      )
    }
    return cells.reverse()
  }

  const getNextPages = (length: number) => {
    const cells = []
    for (let index = 1; currentPage + index < pages && index <= length; index++) {
      cells.push(
        <>
          <Col flex={1}>
            <span onClick={() => setCurrentPage(currentPage + index)}>{currentPage + index}</span>
          </Col>
          <Divider type={"vertical"} style={{ borderColor: "lightgray" }} />
        </>,
      )
    }
    return cells
  }

  return (
    <Row className={"select-page"} justify={"center"} align={"middle"}>
      <Col flex={1}>
        <Button
          shape={"circle"}
          icon={<LeftCircleFilled />}
          disabled={currentPage === 1 || pages == 0}
          onClick={() => setCurrentPage(currentPage - 1)}
        />
      </Col>
      <Divider type={"vertical"} style={{ borderColor: "lightgray" }} />
      {currentPage > 1 && (
        <>
          <Col flex={1}>
            <span onClick={() => setCurrentPage(1)}>1</span>
          </Col>
          <Divider type={"vertical"} style={{ borderColor: "lightgray" }} />
        </>
      )}
      {currentPage - pageWindowSize > 2 && (
        <>
          <Col flex={1}>
            <cite>...</cite>
          </Col>
          <Divider type={"vertical"} style={{ borderColor: "lightgray" }} />
        </>
      )}
      {getPreviousPages(pageWindowSize)}
      <Col flex={1}>
        <InputNumber
          className={"input-nav"}
          value={currentPage}
          min={1}
          max={pages}
          controls={false}
          onChange={(value) => {
            if (value) setCurrentPage(value)
          }}
        />
      </Col>
      <Divider type={"vertical"} style={{ borderColor: "lightgray" }} />
      {getNextPages(pageWindowSize)}
      {currentPage + pageWindowSize < pages - 1 && (
        <>
          <Col flex={1}>
            <cite>...</cite>
          </Col>
          <Divider type={"vertical"} style={{ borderColor: "lightgray" }} />
        </>
      )}
      {currentPage < pages && (
        <>
          <Col flex={1}>
            <span onClick={() => setCurrentPage(pages)}>{pages}</span>
          </Col>
          <Divider type={"vertical"} style={{ borderColor: "lightgray" }} />
        </>
      )}
      <Col flex={1}>
        <Button
          className={"nav-btn"}
          shape={"circle"}
          icon={<RightCircleFilled />}
          disabled={currentPage === pages || pages == 0}
          onClick={() => setCurrentPage(currentPage + 1)}
        />
      </Col>
    </Row>
  )
}
