import React from "react"
import "./500.scss"
import { useNavigate } from "react-router-dom"
import { Result, Button } from "antd"
import { useTranslation } from "react-i18next"
import { TranslationConstants } from "frontend/constants/translations"
import { HomeOutlined, ReloadOutlined } from "@ant-design/icons"
import { PathConstants } from "frontend/constants/paths"

export function Page500() {
  const navigate = useNavigate()

  const { t } = useTranslation()

  return (
    <Result
      status="500"
      title="500"
      subTitle={t(TranslationConstants.i18n_SOMETHING_WENT_WRONG)}
      extra={
        <div className={"extra-buttons-div-500"}>
          <Button type="primary" onClick={() => navigate(PathConstants.HOME)} icon={<HomeOutlined />}>
            {t(TranslationConstants.i18n_BACK_TO_HOME)}
          </Button>
          <Button type="primary" onClick={() => navigate(0)} icon={<ReloadOutlined />}>
            {t(TranslationConstants.i18n_RELOAD_PAGE)}
          </Button>
        </div>
      }
    />
  )
}
