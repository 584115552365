import React from "react"
import ReactDOM from "react-dom"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { ComixtimeErrorBoundary } from "./components/ComixtimeErrorBoundary"
import { ConfigProvider } from "antd"
import dayjs from "dayjs"
import "dayjs/locale/it"
import updateLocale from "dayjs/plugin/updateLocale"
import locale from "antd/locale/it_IT"

dayjs.extend(updateLocale)
dayjs.updateLocale("it", { weekStart: 1 })

// <React.StrictMode> removed because of this:
// https://github.com/ant-design/ant-design/issues/26136
ReactDOM.render(
  <ConfigProvider locale={locale}>
    <BrowserRouter>
      <ComixtimeErrorBoundary>
        <App />
      </ComixtimeErrorBoundary>
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
