import "./ComicExploreCard.scss"
import { ComicData } from "../../../types/entity/comic/ComicData"
import { useNavigate } from "react-router-dom"
import { Card, Col, Image, Row, Tooltip } from "antd"
import { LayoutConstants } from "../../../constants/layout"
import { CLOUD_IMAGE_BASE_PATH } from "../../../constants/apis"
import DefaultImg from "../../../assets/images/defaultComic.png"

export const ComicExploreCard = ({ comic }: { comic: ComicData }) => {
  const navigate = useNavigate()

  return (
    <>
      <Card
        className={"card"}
        hoverable={true}
        cover={
          <Image
            alt={comic.dex_format}
            preview={true}
            height={LayoutConstants.IMG_CARD_HEIGHT}
            width={LayoutConstants.IMG_CARD_WIDTH}
            src={
              comic.multimedia !== undefined && comic.multimedia[0] !== undefined
                ? CLOUD_IMAGE_BASE_PATH + comic.multimedia[0].url
                : DefaultImg
            }
            fallback={DefaultImg}
          />
        }
        title={comic.title}
      />
    </>
  )
}
