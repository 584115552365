import { Col, Container, Row } from "react-bootstrap"
import "./index.scss"
import comixtimeLogo from "../../assets/images/logo-comixtime.png"
import { Dispatch, SetStateAction, useState } from "react"
import LoginForm from "../../components/Forms/LoginForm/LoginForm"
import RegisterForm from "../../components/Forms/RegisterForm/RegisterForm"
import { Button, Card, Image, Space } from "antd"
import Title from "antd/lib/typography/Title"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import FacebookButtons from "../../components/Forms/SocialLogin/FacebookButton/FacebookButton"
import AppleButton from "../../components/Forms/SocialLogin/AppleButton/AppleButton"
import { UrlsConstant } from "../../constants/urls"
import i18n from "../../languages"
import { TranslationConstants } from "../../constants/translations"

export default function Login({
  onExplore,
  firstAccess,
}: {
  onExplore: Dispatch<SetStateAction<boolean>>
  firstAccess: boolean
}) {
  const [isLogin, setIsLogin] = useState<boolean>(true)

  return (
    <>
      <Container className="justify-content-center">
        <Row className="justify-content-md-center">
          <Col lg={4} md={8} sm={12} className="text-center m-3">
            <a href={UrlsConstant.URL_HOME}>
              <FontAwesomeIcon icon={faHome} />
              <p>{i18n.t(TranslationConstants.i18n_GO_TO)} ComiXtime</p>
            </a>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={4} md={8} sm={12} className="text-center mb-3">
            <Card
              className="main-card"
              cover={
                <div className="img-container">
                  <Image alt="logo" src={comixtimeLogo} width={200} height={45} preview={false} className="p-0 m-0" />
                </div>
              }
              bodyStyle={{ padding: "10px" }}
            >
              {isLogin || (
                <b>
                  {i18n.t(TranslationConstants.i18n_ASK_ACCOUNT)}
                  <a onClick={() => setIsLogin(true)}>login</a>
                </b>
              )}
              <Space direction={"vertical"} wrap>
                <FacebookButtons />
                <AppleButton />
              </Space>
              <hr />
              <Title level={5}>{i18n.t(TranslationConstants.i18n_OR)}</Title>
              <hr />
              <Title level={5}>{i18n.t(TranslationConstants.i18n_INSERT_INFO)}</Title>
              {isLogin && <LoginForm firstAccess={firstAccess} />}
              {!isLogin && <RegisterForm />}
              <div>
                <b>
                  {i18n.t(TranslationConstants.i18n_RECOVER_PASSWORD)} <br />
                  {i18n.t(TranslationConstants.i18n_CLICK)}{" "}
                  <a href={UrlsConstant.URL_RECOVER_PWD}>{i18n.t(TranslationConstants.i18n_HERE)}</a>{" "}
                  {i18n.t(TranslationConstants.i18n_TO_RESTORE)}
                </b>
              </div>
            </Card>
          </Col>
        </Row>
        {!isLogin || (
          <Row className="justify-content-md-center botton-button">
            <Col lg={4} md={8} sm={12} className="text-white text-center m-3">
              <p>{i18n.t(TranslationConstants.i18n_NOT_REGISTERED)}</p>
              <Button type="default" className="mb-3 register" onClick={() => setIsLogin(false)}>
                {i18n.t(TranslationConstants.i18n_REGISTER)}
              </Button>
              <br />
              <Button type="default" className="mb-3 register" onClick={() => onExplore(true)}>
                Esplora il database
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}
