import { Col, Container, Row } from "react-bootstrap"
import "./index.scss"
import comixtimeLogo from "../../assets/images/logo-comixtime.png"
import { Button, Card, Image, notification, Spin } from "antd"
import Title from "antd/lib/typography/Title"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { AccessAPI } from "../../api/common/AccessAPI"

export default function Consents({ checkbox }: { checkbox: boolean }) {
  const [loading, setLoading] = useState<boolean>(false)

  const handleInput = () => {
    setLoading(true)
    AccessAPI.acceptPubConsents("PUB")
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        notification.error({
          message: "Qualcosa è andato storto",
          duration: 4,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Spin spinning={loading}>
      <Container className="justify-content-center">
        <Row className="justify-content-md-center">
          <Col lg={4} md={8} sm={12} className="mb-3">
            <Card
              className="main-card"
              cover={
                <div className="img-container">
                  <Image alt="logo" src={comixtimeLogo} width={200} height={45} preview={false} className="p-0 m-0" />
                </div>
              }
              bodyStyle={{ padding: "10px" }}
            >
              <div className="text-div">
                <Title level={5}>TERMINI E CONDIZIONI</Title>
                <div>
                  ComiXtime è una piattaforma digitale che consente agli utenti di gestire collezioni di fumetti in
                  formato digitale attraverso appositi applicativi web;
                  <br />
                  <br />
                  L'Editore è un editore di fumetti che desidera entrare nella community ComiXtime e certificare la
                  propria produzione all'interno di ComiXtime;
                  <br />
                  <br />
                  Le Parti concordano quanto segue:
                  <ul>
                    <li>
                      <b>Certificazione dell'Editore</b>
                      <br />
                      L'Editore diventerà un editore certificato all'interno di ComiXtime e i suoi dati saranno
                      classificati come certificati.
                    </li>
                    <li>
                      <b>Servizio di assistenza</b>
                      <br />
                      ComiXtime fornirà all'Editore un servizio di assistenza completo e gratuito per l'inserimento dei
                      dati relativi agli Albi e a tutti i dati correlati all'interno di ComiXtime.
                    </li>
                    <li>
                      <b>Comunicazione reciproca</b>
                      <br />
                      Le Parti possono comunicare la collaborazione tramite i propri canali.
                    </li>
                    <li>
                      <b>Durata e recesso</b>
                      <br />
                      Questo Accordo avrà durata annuale con rinnovo automatico, salvo recesso di una delle parti con
                      preavviso scritto di almeno 30 giorni.
                    </li>
                  </ul>
                </div>
              </div>
              {checkbox && (
                <Row className={"input-row"}>
                  <div className="d-grid mt-2 mb-0">
                    <Button
                      type="default"
                      size="large"
                      htmlType="button"
                      icon={<FontAwesomeIcon className="fa-2x" icon={faSignInAlt} />}
                      className="login-btn"
                      onClick={handleInput}
                    >
                      Accetta Termini e Condizioni
                    </Button>
                  </div>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Spin>
  )
}
