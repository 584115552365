export const LayoutConstants = {
  COLLAPSED_SIDER_WIDTH: 50,
  SIDER_WIDTH: 200,
  IMG_CARD_HEIGHT: 330,
  IMG_CARD_WIDTH: 280,
  IMG_TABLE_HEIGHT: 150,
  IMG_TABLE_WIDTH: 112,
  SEARCH_LOGO_WIDTH: 500,
  SEARCH_LOGO_HEIGHT: 200,
} as const
