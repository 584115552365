import { MenuItemType, SubMenuType } from "antd/es/menu/interface"
import React, { useEffect, useState } from "react"
import "./DefaultHeader.scss"
import ProfileDropdown from "../../Dropdown/ProfileDropdown/ProfileDropdown"
import useAuthContext from "../../../context/auth/useAuthContext"
import comixtimeLogo from "../../../assets/images/logo-comixtime.png"
import smallCxtLogo from "../../../assets/images/small-logo.png"
import { PathConstants } from "../../../constants/paths"
import { useNavigate } from "react-router"
import { Col, Menu, MenuProps, Row } from "antd"
import navigation from "../../../navigation"

function DefaultHeader({ setService }: { setService: (service: "dex" | "pub") => void }) {
  const user = useAuthContext()
  const navigate = useNavigate()
  const menuCurrentKey = location.pathname

  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
  }, [])

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth)
  }

  const menuItems: MenuProps["items"] = navigation
    .filter(function (navigationElem) {
      if (navigationElem.name === "My Comics") {
        if (user.role.includes(8)) return navigationElem
      } else return navigationElem
    })
    .map(function (navigationElem) {
      const subMenu: SubMenuType = {
        label: navigationElem.name,
        key: navigationElem.path,
        icon: navigationElem.icon,
        disabled: navigationElem.disabled,
        children: [],
      }
      if (navigationElem.paths === undefined || navigationElem.paths.length === 0) {
        return subMenu
      } else {
        const children: MenuItemType[] = navigationElem.paths.map(function (nestedNavigationElem) {
          return {
            label: nestedNavigationElem.name,
            key: navigationElem.path.concat(nestedNavigationElem.path),
            icon: nestedNavigationElem.icon,
            disabled: nestedNavigationElem.disabled,
            onClick: () => {
              setService(navigationElem.name === "My Comics" ? "pub" : "dex")
            },
          }
        })
        return { ...subMenu, children: children }
      }
    })

  return (
    <Row align={"middle"} justify={"start"} className={"menu"}>
      <Col className="logo" span={3} id={"logo-col"}>
        <img
          src={screenWidth >= 1430 ? comixtimeLogo : smallCxtLogo}
          className="main-logo"
          height="40"
          alt="Comixtime"
          onClick={() => {
            navigate(PathConstants.HOME)
          }}
          style={{ cursor: "pointer" }}
        />
      </Col>
      <Col span={17}>
        <Menu
          theme={"dark"}
          selectedKeys={[menuCurrentKey]}
          mode={"horizontal"}
          onClick={(elem) => navigate(elem.key)}
          items={menuItems}
          className={"menu"}
        ></Menu>
      </Col>
      <Col offset={3} span={1}>
        <ProfileDropdown {...user}></ProfileDropdown>
      </Col>
    </Row>
  )
}

export { DefaultHeader }
