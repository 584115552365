export const TranslationConstants = {
  i18n_COMICS: "COMICS",
  i18n_SERIES: "SERIES",
  i18n_COMICSETS: "COMICSETS",
  i18n_AUTHORS: "AUTHORS",
  i18n_PERSONAS: "PERSONAS",
  i18n_GENRES: "GENRES",
  i18n_PUBLISHERS: "PUBLISHERS",
  i18n_SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  i18n_PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
  i18n_BACK_TO_HOME: "BACK_TO_HOME",
  i18n_RELOAD_PAGE: "RELOAD_PAGE",
  i18n_LOADING: "LOADING",
  i18n_SHOW: "SHOW",
  i18n_ELEMENTS: "ELEMENTS",
  i18n_MISSING_DATA: "MISSING_DATA",
  i18n_SEARCH: "SEARCH",
  i18n_NO_FILTER: "NO_FILTER",
  i18n_DATA_NOT_FOUND: "DATA_NOT_FOUND",
  i18n_EMPTY_FIELD: "EMPTY_FIELD",
  i18n_WRONG_EMAIL: "WRONG_EMAIL",
  i18n_WRONG_PASSWORD: "WRONG_PASSWORD",
  i18n_CONFIRM_PASSWORD: "CONFIRM_PASSWORD",
  i18n_WRONG_CONFIRM: "WRONG_CONFIRM",
  i18n_NAME: "NAME",
  i18n_SURNAME: "SURNAME",
  i18n_GO_TO: "GO_TO",
  i18n_ASK_ACCOUNT: "ASK_ACCOUNT",
  i18n_OR: "OR",
  i18n_INSERT_INFO: "INSERT_INFO",
  i18n_RECOVER_PASSWORD: "RECOVER_PASSWORD",
  i18n_CLICK: "CLICK",
  i18n_HERE: "HERE",
  i18n_TO_RESTORE: "TO_RESTORE",
  i18n_NOT_REGISTERED: "NOT_REGISTERED",
  i18n_REGISTER: "REGISTER",
  i18n_MODIFY: "MODIFY",
} as const
