import { Button, Checkbox, Input, notification, Spin } from "antd"
import { AccessAPI } from "../../../api/common/AccessAPI"
import { Col, Row } from "react-bootstrap"
import i18n from "i18next"
import { TranslationConstants } from "../../../constants/translations"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faLock, faSignInAlt } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { useState } from "react"

export default function LoginForm({ firstAccess }: { firstAccess: boolean }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false)

  const [email, setEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [consents, setConsents] = useState<boolean>(false)

  const handleInput = () => {
    if (email !== undefined && password !== undefined && (!firstAccess || consents)) {
      setLoading(true)
      AccessAPI.login({ email, password, consents })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          notification.error({
            message: "Email e/o Password non valide!",
            duration: 4,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setShowValidationErrors(true)
    }
  }

  const onFieldChange = () => {
    setShowValidationErrors(false)
  }

  return (
    <div className={"login-form"}>
      <Spin spinning={loading}>
        <Col>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={"E-mail"}
                value={email}
                type={"email"}
                prefix={<FontAwesomeIcon icon={faEnvelope} />}
                onChange={(e) => {
                  onFieldChange()
                  const email = e.target.value === "" ? undefined : e.target.value
                  setEmail(email)
                }}
              />
              {showValidationErrors && !email && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_EMPTY_FIELD)}</span>
              )}
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={"Password"}
                value={password}
                type={"password"}
                prefix={<FontAwesomeIcon icon={faLock} />}
                onChange={(e) => {
                  onFieldChange()
                  const password = e.target.value === "" ? undefined : e.target.value
                  setPassword(password)
                }}
              />
              {showValidationErrors && !password && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_EMPTY_FIELD)}</span>
              )}
            </Col>
          </Row>
          {firstAccess && (
            <Row className={"input-row"}>
              <div className="d-grid mt-2 mb-0">
                <Checkbox
                  onChange={(e) => {
                    onFieldChange()
                    setConsents(e.target.checked)
                  }}
                >
                  Accetta&nbsp;
                  <Link to={"/consents"} target={"_blank"}>
                    Termini e Condizioni
                  </Link>
                </Checkbox>
                {showValidationErrors && firstAccess && !consents && (
                  <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_EMPTY_FIELD)}</span>
                )}
              </div>
            </Row>
          )}
          <Row className={"input-row"}>
            <div className="d-grid mt-2 mb-0">
              <Button
                type="default"
                size="large"
                htmlType="button"
                icon={<FontAwesomeIcon className="fa-2x" icon={faSignInAlt} />}
                className="login-btn"
                onClick={handleInput}
              >
                Login
              </Button>
            </div>
          </Row>
        </Col>
      </Spin>
    </div>
  )
}
