import { Button, Input, notification, Spin } from "antd"
import { AccessAPI } from "../../../api/common/AccessAPI"
import { useState } from "react"
import i18n from "i18next"
import { TranslationConstants } from "../../../constants/translations"
import { Col, Row } from "react-bootstrap"
import { RegexpConstants } from "../../../constants/regexp"

export default function RegisterForm() {
  const [loading, setLoading] = useState<boolean>(false)
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false)
  const [wrongPasswordPattern, setWrongPasswordPattern] = useState<boolean>(false)
  const [wrongEmailPattern, setWrongEmailPattern] = useState<boolean>(false)
  const [wrongConfirm, setWrongConfirm] = useState<boolean>(false)

  const [email, setEmail] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [confirm, setConfirm] = useState<string | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(undefined)
  const [surname, setSurname] = useState<string | undefined>(undefined)

  const handleInput = () => {
    if (
      email !== undefined &&
      password !== undefined &&
      confirm !== undefined &&
      name !== undefined &&
      surname !== undefined &&
      !wrongEmailPattern &&
      !wrongPasswordPattern &&
      !wrongConfirm
    ) {
      setLoading(true)
      AccessAPI.register({ email, password, confirm, name, surname })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          notification.error({
            message: i18n.t(TranslationConstants.i18n_SOMETHING_WENT_WRONG),
            duration: 4,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setShowValidationErrors(true)
    }
  }

  const onFieldChange = () => {
    setShowValidationErrors(false)
  }

  return (
    <div className={"register-form"}>
      <Spin spinning={loading}>
        <Col>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>E-mail (*)</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={"E-mail"}
                value={email}
                type={"email"}
                onChange={(e) => {
                  onFieldChange()
                  const email = e.target.value === "" ? undefined : e.target.value
                  setEmail(email)
                }}
                onBlur={() => {
                  if (email) setWrongEmailPattern(!RegexpConstants.EMAIL_PATTERN.test(email))
                }}
              />
              {showValidationErrors && !email && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_EMPTY_FIELD)}</span>
              )}
              {wrongEmailPattern && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_WRONG_EMAIL)}</span>
              )}
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>Password (*)</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={"Password"}
                value={password}
                type={"password"}
                onChange={(e) => {
                  onFieldChange()
                  const password = e.target.value === "" ? undefined : e.target.value
                  setPassword(password)
                }}
                onBlur={() => {
                  if (password) setWrongPasswordPattern(!RegexpConstants.PASSWORD_PATTERN.test(password))
                }}
              />
              {showValidationErrors && !password && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_EMPTY_FIELD)}</span>
              )}
              {wrongPasswordPattern && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_WRONG_PASSWORD)}</span>
              )}
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>{i18n.t(TranslationConstants.i18n_CONFIRM_PASSWORD)} (*)</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={"Password"}
                value={confirm}
                type={"password"}
                onChange={(e) => {
                  onFieldChange()
                  const confirm = e.target.value === "" ? undefined : e.target.value
                  setConfirm(confirm)
                }}
                onBlur={() => {
                  setWrongConfirm(confirm !== password)
                }}
              />
              {showValidationErrors && !confirm && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_EMPTY_FIELD)}</span>
              )}
              {wrongConfirm && <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_WRONG_CONFIRM)}</span>}
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>{i18n.t(TranslationConstants.i18n_NAME)} (*)</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={i18n.t(TranslationConstants.i18n_NAME)}
                value={name}
                onChange={(e) => {
                  onFieldChange()
                  const name = e.target.value === "" ? undefined : e.target.value
                  setName(name)
                }}
              />
              {showValidationErrors && !name && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_EMPTY_FIELD)}</span>
              )}
            </Col>
          </Row>
          <Row className={"label-row"}>
            <Col>
              <span className={"label"}>{i18n.t(TranslationConstants.i18n_SURNAME)} (*)</span>
            </Col>
          </Row>
          <Row className={"input-row"}>
            <Col>
              <Input
                placeholder={i18n.t(TranslationConstants.i18n_SURNAME)}
                value={surname}
                onChange={(e) => {
                  onFieldChange()
                  const surname = e.target.value === "" ? undefined : e.target.value
                  setSurname(surname)
                }}
              />
              {showValidationErrors && !surname && (
                <span className={"error-span"}>{i18n.t(TranslationConstants.i18n_EMPTY_FIELD)}</span>
              )}
            </Col>
          </Row>
          <Row className={"input-row"}>
            <div className="d-grid mt-2 mb-0">
              <Button type="default" size="large" htmlType="button" className="login-btn" onClick={handleInput}>
                Continua
              </Button>
            </div>
          </Row>
        </Col>
      </Spin>
    </div>
  )
}
