import { commonApi, userApi } from "./axiosConfig"
import * as Constants from "../../constants/apis"
import { userLogin } from "../../types/user/userLogin"
import { userRegister } from "../../types/user/userRegister"

export const AccessAPI = {
  login: async function (user: userLogin) {
    return await commonApi.request({
      url: Constants.LOGIN_PATH,
      method: Constants.POST,
      data: {
        email: user.email,
        password: user.password,
        consents: user.consents,
      },
    })
  },
  register: async function (user: userRegister) {
    return await commonApi.request({
      url: Constants.USER_PATH,
      method: Constants.POST,
      data: {
        email: user.email,
        password: user.password,
        name: user.name,
        surname: user.surname,
      },
    })
  },
  acceptPubConsents: async function (service: string) {
    return await userApi.request({
      url: Constants.ACCEPT_PUB_CONSENTS,
      method: Constants.PATCH,
      headers: { "Comixtime-Service": service },
    })
  },
  getUser: async function () {
    return await commonApi.request({
      url: Constants.USER_PATH,
      method: Constants.GET,
    })
  },
  verifyToken: async function () {
    return await commonApi.request({
      url: Constants.AUTH_PATH,
      method: Constants.GET,
    })
  },
  logout: async function () {
    return await commonApi.request({
      url: Constants.LOGOUT_PATH,
      method: Constants.DELETE,
    })
  },
  appleLogin: async function (token: string, name?: string, surname?: string) {
    return await commonApi.request({
      url: Constants.APPLE_PATH,
      method: Constants.POST,
      data: {
        tokenApple: token,
        name: name,
        surname: surname,
      },
    })
  },
  facebookLogin: async function (token: string, idFacebook: string, email?: string, name?: string, surname?: string) {
    return await commonApi.request({
      url: Constants.FACEBOOK_PATH,
      method: Constants.POST,
      data: {
        tokenFacebook: token,
        userIdFacebook: idFacebook,
        email: email,
        name: name,
        surname: surname,
      },
    })
  },
}
