import React, { Component, ErrorInfo } from "react"
import "./ComixtimeErrorBoundary.scss"
import { Page500 } from "frontend/views/500/500"

type ComixtimeErrorBoundaryState = {
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ComixtimeErrorBoundary extends Component<any, ComixtimeErrorBoundaryState> {
  constructor(props: any) {
    super(props)

    this.state = {
      error: null,
      errorInfo: null,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    const { error, errorInfo } = this.state
    const { children } = this.props

    if (error) {
      errorInfo
      return <Page500 />
    }

    return children
  }
}

export default ComixtimeErrorBoundary
