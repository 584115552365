import { Col, Result, Row, Select, Spin } from "antd"
import { ReactNode } from "react"
import { LayoutConstants } from "../../../constants/layout"
import "./CardPanel.scss"
import { DebounceInput } from "react-debounce-input"
import { NavigatePages } from "../NavigatePages/NavigatePages"

export const CardPanel = ({
  cards,
  loading,
  isFilterSiderCollapsed,
  pages,
  initialPage,
  pageWindowSize,
  isEmpty,
  changePage,
  onSearch,
  setNumCards,
  header,
}: {
  cards: ReactNode[]
  loading: boolean
  isFilterSiderCollapsed: boolean
  pages: number
  initialPage: number
  pageWindowSize: number
  isEmpty: boolean
  changePage: (page: number) => void
  onSearch: (value: string) => void
  setNumCards: (value: number) => void
  header?: { entity: string; name: string }
}) => {
  return (
    <div
      className={"card-panel"}
      style={{
        paddingRight: isFilterSiderCollapsed ? LayoutConstants.COLLAPSED_SIDER_WIDTH : LayoutConstants.SIDER_WIDTH,
      }}
    >
      <Col>
        {header && (
          <Row className={"header"}>
            <h3>
              {header.entity === "series" ? "Collana: " : "Serie: "}
              <cite>{header.name}</cite>
            </h3>
          </Row>
        )}
        <Row className={"card-header"} justify={"center"} align={"middle"}>
          <Col flex={4} className={"search-field"}>
            <DebounceInput
              placeholder={"Cerca..."}
              type={"text"}
              debounceTimeout={400}
              onChange={(e) => onSearch(e.target.value)}
            />
          </Col>
          <Col flex={12} offset={1} className={"select-page"}>
            <NavigatePages
              pages={pages}
              initialPage={initialPage}
              pageWindowSize={pageWindowSize}
              changePage={changePage}
            />
          </Col>
          <Col flex={2}>
            <Select className={"select-num-cards"} defaultValue={12} onSelect={setNumCards}>
              <Select.Option value={12}>12</Select.Option>
              <Select.Option value={24}>24</Select.Option>
              <Select.Option value={48}>48</Select.Option>
              <Select.Option value={96}>96</Select.Option>
            </Select>
          </Col>
        </Row>
        <Spin className={"mt-5"} spinning={loading} size={"large"}>
          <Row className={"p-3"} justify={"center"} align={"middle"} gutter={[20, 30]}>
            {isEmpty && !loading ? (
              <Col flex={1}>
                <Result status="warning" title="Non risultano presenti dati per la ricerca effettuata." />
              </Col>
            ) : (
              cards.map((card: ReactNode, index: number) => {
                return (
                  <Col lg={10} xl={8} xxl={6} key={index}>
                    <Row justify={"center"} align={"middle"}>
                      {card}
                    </Row>
                  </Col>
                )
              })
            )}
          </Row>
        </Spin>
        <Col flex={1} className={"select-page m-2"}>
          <NavigatePages
            pages={pages}
            initialPage={initialPage}
            pageWindowSize={pageWindowSize}
            changePage={changePage}
          />
        </Col>
      </Col>
    </div>
  )
}
