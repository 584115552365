import { AuthContextData } from "../../../types/user/AuthContextData"
import { Button, Dropdown, MenuProps, notification, Space } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons"
import { AccessAPI } from "../../../api/common/AccessAPI"
import i18n from "i18next"
import { TranslationConstants } from "../../../constants/translations"
import "./ProfileDropdown.scss"

const ProfileDropdown = (user: AuthContextData) => {
  function handleLogout() {
    AccessAPI.logout()
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        notification.error({
          message: i18n.t(TranslationConstants.i18n_SOMETHING_WENT_WRONG),
          duration: 4,
        })
      })
  }

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "logout") handleLogout()
  }

  const items: MenuProps["items"] = [
    { key: "profile", label: "Profile", icon: <FontAwesomeIcon icon={faUser} size={"lg"} />, disabled: true },
    {
      key: "logout",
      label: "Logout",
      icon: <FontAwesomeIcon icon={faPowerOff} size={"lg"} />,
      danger: true,
    },
  ]
  return (
    <Space align={"center"}>
      <Dropdown
        className={"user-dropdown"}
        menu={{ items, onClick }}
        placement={"bottom"}
        arrow={{ pointAtCenter: true }}
      >
        <Button type={"primary"} size={"large"}>
          <FontAwesomeIcon icon={faUser} className={"me-2"} size={"lg"} />
        </Button>
      </Dropdown>
    </Space>
  )
}

export default ProfileDropdown
