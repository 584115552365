import axios from "axios"
import * as Constants from "../../constants/apis"

export const platformApi = axios.create({
  baseURL: Constants.CODEK_API_URL + Constants.PLATFORM + Constants.V1,
  withCredentials: true,
})

const errorHandler = (error: any) => {
  const statusCode = error.response ? error.response.status : null

  if (statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

platformApi.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})
